import { initializeApp } from "firebase/app";
export default initializeApp({
  apiKey: import.meta.env.__FB_API_KEY,
  appId: import.meta.env.__FB_ID_APP,
  authDomain: import.meta.env.__FB_AUTH_DOMAIN,
  messagingSenderId: import.meta.env.__FB_ID_MESSAGING_SENDER,
  projectId: import.meta.env.__FB_ID_PROJECT,
  storageBucket: import.meta.env.__FB_STORAGE_BUCKET,
  databaseURL: import.meta.env.__FB_DATABASE_URL,
  // measurementId
});
